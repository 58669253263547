<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <div class="brand-text text-primary ml-1 nav navbar-nav flex-row d-flex align-items-center">
          <div class="mx-1">
            <b-img width="40" height="40" fluid :src="appLogoImage" alt="logo" />
          </div>
           <!-- {{ appName }} -->
          <h2 class="m-0 brand-text">
          {{ appName }} 
          </h2>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col v-bind:style="{ backgroundImage: 'url(' + imgUrl + ')' }" lg="8" class="d-none d-lg-flex align-items-center p-5 cover">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <!-- <b-img fluid :src="imgUrl" alt="Login V2" /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            <div class="m-0 brand-text text-primary nav navbar-nav flex-row d-flex align-items-center">
       
              <h4 class="m-0 brand-text">
                <!--                {{ appName }}-->
                <br />
               Medical Auditing System
              </h4>
            </div></b-card-title
          >
          <!--          <b-card-text class="mb-2"> Please sign-in to your account and start the adventure</b-card-text>-->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="login-email" v-model="loginForm.email" :state="errors.length > 0 ? false : null" name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                                      <small>Forgot Password?</small>
                                    </b-link> -->
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="loginForm.password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                
                </validation-provider>
              </b-form-group>
        
              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button :class="$store.state.app.load ? 'disabled_all' : ''" type="submit" variant="primary" block :disabled="invalid"
                ><span v-if="!$store.state.app.load">Sign in</span>
                <b-spinner v-if="$store.state.app.load" small :label="$t('Global.loading')"></b-spinner>
              </b-button>
            </b-form>
          </validation-observer>

     
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { BRow, BCol, BLink, BFormGroup, BSpinner, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip } from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { $themeConfig } from '@themeConfig';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
     
      status: '',
      loginForm: {
        email: '',
        password: '',
      },
      sideImg: require('@/assets/images/pages/background.jpg'),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/background.jpg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login: function () {
      this.$refs.loginForm
        .validate()
        .then(success => {
          if (success) {
            this.$store.dispatch('users/login', this.loginForm).then((res) => {

console.log(res);

if(res == 'error')
{
  this.$swal({
            icon: 'error',
            title: 'Login Failed',
            text: 'Invalid email or password',
            timer: 2000,
          })
}
else{

              if (this.authUser().id) {
                this.$store.dispatch('roles/getAuthUserRolesPermissions', this.authUser().id).then(() => {
         
                  this.$router.push({name : 'dashboard'})
                });
              }
         
}
          
          
          
          
          });
          }
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error);
          console.log(this.$refs.loginForm);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';


 .swal-text {
  background-color: #FEFAE3;
  padding: 17px;
  border: 1px solid #F0E1A1;
  display: block;
  margin: 22px;
  text-align: center;
  color: #9c4526;
} 
</style>
